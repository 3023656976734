const REGIONS = ['US', 'CA', 'US-NEW']

const URLS = {
  [REGIONS[0]]: ['https://d2mhexn86y1lr4.cloudfront.net'],
  [REGIONS[1]]: ['https://d36znmlvduruqh.cloudfront.net'],
  [REGIONS[2]]: [
    'https://hiorka-website-storage-prod.s3.us-west-2.amazonaws.com'
  ]
}

const getCDNRootUrl = (region = 'US') => {
  return URLS[region]?.[0]
}

/**
 * Join cdn path
 * @param {string} url assets path
 * @param {string} region cdn region. Optional, Read NEXT_PUBLIC_REGION for env, if null default as 'US'
 * @returns Full path. Example: https://d2mhexn86y1lr4.cloudfront.net/website/imgs/a.jpg
 */
const joinCDN = (url, region = '') => {
  if (!url) {
    console.error('CDN: url is required')
    return null
  }
  if (['http://', 'https://'].some((item) => url.startsWith(item))) {
    return url
  }
  if (!region) {
    region = process.env.NEXT_PUBLIC_REGION || 'US'
  }
  const ROOT_URL = getCDNRootUrl(region)
  if (!ROOT_URL) {
    console.error('CDN: region is invalid')
    return null
  }
  const head = url[0]
  if (head === '/') {
    return ROOT_URL + url
  }
  return ROOT_URL + '/' + url
}

export { REGIONS }

export default joinCDN
