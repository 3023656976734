import { ACCOUNT_MENU, AccountMenu } from './AccountMenu'
import { Box, BoxProps, Stack } from '@mui/material'
import { Menu, MenuBaseProps } from './Menu'
import React, { useEffect, useMemo, useState } from 'react'

import Image from 'next/image'
import Link from 'next/link'
import { OrkaLogo } from '@evocolabs/orkaui'
import { menuList } from './utils'
import styles from './styles.module.scss'

const DesktopHeader = ({
  isWhiteBg,
  className,
  ...restProps
}: { isWhiteBg: boolean } & BoxProps) => {
  const [activeMenu, setActiveMenu] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)
  const [isScrolled, setIsScrolled] = useState(false)

  const resetActiveMenu = () => setActiveMenu('')
  const handleMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    title: string
  ) => {
    setAnchorEl((event.target as HTMLElement).closest('div'))
    setActiveMenu(title)
  }

  const themeMode = useMemo(
    () =>
      activeMenu && activeMenu !== ACCOUNT_MENU
        ? 'light'
        : isScrolled || isWhiteBg
          ? 'light'
          : 'dark',
    [activeMenu, isScrolled, isWhiteBg]
  )

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0)
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const Logo = (
    <Box display="flex" id="header_logo">
      <Link href={'/'} aria-label="Home">
        {isWhiteBg && (!activeMenu || activeMenu === ACCOUNT_MENU) ? (
          <OrkaLogo />
        ) : themeMode === 'light' ? (
          <Image
            loading="eager"
            src="/imgs/orkaTwo/logoAndOrka.svg"
            alt="orka-logo-type"
            width="154"
            height="40"
          />
        ) : (
          <OrkaLogo color="white" />
        )}
      </Link>
    </Box>
  )

  const headerClassName = useMemo(
    () =>
      [
        styles.headerPc,
        activeMenu && activeMenu !== ACCOUNT_MENU
          ? styles.headerPcMenuActivated
          : '',
        isScrolled || isWhiteBg ? styles.headerPcWhiteBg : ''
      ].join(' '),
    [activeMenu, isScrolled, isWhiteBg]
  )

  const menuBaseProps: MenuBaseProps = {
    themeMode,
    activeMenu,
    onActivateMenu: handleMenuClick,
    resetActiveMenu
  }

  return (
    <Box className={`${styles.headerPcWrapper} ${className}`}>
      <Box
        className={headerClassName}
        onMouseLeave={() => {
          activeMenu !== ACCOUNT_MENU && resetActiveMenu()
        }}
        {...restProps}
      >
        <Stack justifyContent="center">{Logo}</Stack>
        <Box className={styles.menuWrapper}>
          <Menu menuList={menuList} anchorEl={anchorEl} {...menuBaseProps} />
          <AccountMenu {...menuBaseProps} />
        </Box>
      </Box>
      <Box
        className={styles.backdrop}
        display={activeMenu ? 'block' : 'none'}
        onClick={() => setActiveMenu('')}
      />
    </Box>
  )
}

export default DesktopHeader
