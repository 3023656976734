import {
  Box,
  BoxProps,
  ButtonBase,
  Drawer,
  DrawerProps,
  Stack
} from '@mui/material'
import { accountList, generateId } from './utils'
import { useCallback, useEffect, useState } from 'react'

import AccordionList from './AccordionList'
import Close24 from '@evocolabs/icons/lib/Close24'
import Link from 'next/link'
import ListItem from './ListItem'
import Menu32 from '@evocolabs/icons/lib/Menu32'
import { OrkaLogoType } from '@evocolabs/orkaui'
import { RootState } from '@/src/redux/store'
import User24 from '@evocolabs/icons/lib/User24'
import styles from './styles.module.scss'
import { useBackend } from '@/src/providers/BackendProvider'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'

const MENU_PANEL = 'menu'
const ACCOUNT_PANEL = 'account'
const panel = [MENU_PANEL, ACCOUNT_PANEL] as const
type PanelType = (typeof panel)[number]

const MenuPanel = (props: BoxProps) => {
  return (
    <Box height="100vh" px={2} py={3} {...props}>
      <AccordionList />
    </Box>
  )
}

const MobileHeader = ({ className, ...restProps }: BoxProps) => {
  const router = useRouter()
  const [activePanel, setActivePanel] = useState('')
  const useInfoStore = useSelector(
    (state: RootState) => state.base.userInfo
  ) || { isLogin: false }
  const isLogin = useInfoStore.isLogin
  const headerHeight = useSelector((state: RootState) => state.header.height) + 48
  const { userSignOut } = useBackend()

  useEffect(() => {
    setActivePanel('')
  }, [router])

  const togglePanel = (panel: PanelType) => () => {
    setActivePanel(activePanel === panel ? '' : panel)
  }

  const drawerProps: DrawerProps = {
    anchor: 'top',
    onClose: () => setActivePanel(''),
    className: styles.panelWrapper
  }

  const renderAccountPanel = useCallback(() => {
    const res = accountList
      .slice(0, 4)
      .map(({ title, path }) => (
        <ListItem
          title={title}
          path={path}
          key={title}
          id={`header_${generateId(title)}`}
        />
      ))
    if (isLogin) {
      const { title, path } = accountList[4]
      res[3] = (
        <ListItem
          title={title}
          path={path}
          key={title}
          id={`header_${generateId(title)}`}
          onClick={userSignOut}
        />
      )
    }
    return res
  }, [isLogin])

  return (
    <Box
      className={`${styles.headerMobileWrapper} ${className}`}
      {...restProps}
    >
      <Box className={styles.headerMobile}>
        <Stack id="header_menu">
          <ButtonBase onClick={togglePanel(MENU_PANEL)} aria-label="Menu">
            {activePanel === MENU_PANEL ? <Close24 /> : <Menu32 size={24} />}
          </ButtonBase>
        </Stack>
        <Stack id="header_logo">
          <Link href={'/'} aria-label="Home">
            <OrkaLogoType />
          </Link>
        </Stack>
        <Stack id="header_account">
          <ButtonBase onClick={togglePanel(ACCOUNT_PANEL)} aria-label="Account">
            <User24 />
          </ButtonBase>
        </Stack>
      </Box>
      <Drawer open={activePanel === MENU_PANEL} {...drawerProps}>
        <MenuPanel mt={`${headerHeight}px`} />
      </Drawer>
      <Drawer open={activePanel === ACCOUNT_PANEL} {...drawerProps}>
        <Stack gap={1} className={styles.accountPanel} mt={`${headerHeight}px`}>
          {renderAccountPanel()}
        </Stack>
      </Drawer>
    </Box>
  )
}

export default MobileHeader
