import { Stack, StackProps } from '@mui/material'
import Image from 'next/image'
import React from 'react'
import { generateId } from './utils'
import { ListItem, ListItemProps } from './ListItem'
export interface MenuListProps extends StackProps {
  id: string
  src?: string
  items: ListItemProps[]
  extraListNodes?: React.ReactNode
}

export const MenuList = ({
  id,
  items,
  src,
  extraListNodes,
  ...restProps
}: MenuListProps) => {
  return (
    <Stack gap={5} direction="row" {...restProps}>
      <Stack>
        {items.map(({ id, title, path, ...restItemProps }) => {
          const itemId = id ?? `${id}-${generateId(title)}`
          return (
            <ListItem
              id={itemId}
              key={itemId}
              title={title}
              path={path}
              {...restItemProps}
            />
          )
        })}
        {extraListNodes ? <Stack spacing="4px">{extraListNodes}</Stack> : null}
      </Stack>
      {!!src ? (
        <Image
          src={src}
          width={240}
          height={164}
          alt={id}
          style={{ objectFit: 'cover', borderRadius: '8px' }}
          unoptimized
        />
      ) : null}
    </Stack>
  )
}

export default MenuList
