import ListItem, { ListItemProps } from './ListItem'

import { MenuListType } from './Menu'
import { getImage } from '@/src/utils/media'

export const MENU_LIST_AB_TEST_KEY = 'menu-content-simplified'

const generateId = (value: string) => {
  return value.toLowerCase().replace(' ', '-')
}

const menuList: MenuListType[] = [
  {
    title: 'Product',
    path: '/OrkaTwo',
    items: [
      { title: 'Explore Orka Two', path: '/OrkaTwo' },
    ],
    src: getImage('header_orkaTwo.jpg', '', '/website/imgs')
  },
  {
    title: 'Services',
    path: '/OrkaCare',
    items: [
      { title: 'OrkaCare', path: '/OrkaCare' },
    ]
  },
  {
    title: 'About',
    path: '/HearingBlog',
    items: [
      { title: 'Who We Are', path: '/AboutUs' },
      { title: 'Why We Care', path: '/world-hearing-day' },
      { title: 'Hearing Blog', path: '/HearingBlog' }
    ]
  },
  {
    title: 'Support',
    path: '/Support',
    items: [
      { title: 'Support Center', path: '/Support' },
    ],
  }
]

export const accountList: ListItemProps[] = [
  {
    title: 'Account Details',
    path: '/Account'
  },
  {
    title: 'Service Center',
    path: '/Account#service'
  },
  { title: 'My Orders', path: '/Account#orders' },
  {
    title: 'Login',
    path: '/SignIn'
  },
  {
    title: 'Log out',
    path: '/Home'
  }
]

export { generateId, menuList }
