import { ButtonBase, Popper } from '@mui/material'
import User24 from '@evocolabs/icons/lib/User24'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { MenuList } from './MenuList'
import styles from './styles.module.scss'
import { accountList, generateId } from './utils'
import { MenuBaseProps } from './Menu'
import { ListItemProps } from './ListItem'
import { RootState } from '@/src/redux/store'
import { useBackend } from '@/src/providers/BackendProvider'

export const ACCOUNT_MENU = 'Account'

export const AccountMenu = ({
  themeMode,
  activeMenu,
  onActivateMenu,
  resetActiveMenu
}: MenuBaseProps) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const useInfoStore = useSelector(
    (state: RootState) => state.base.userInfo
  ) || { isLogin: false }
  const isLogin = useInfoStore.isLogin
  const { userSignOut } = useBackend()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (activeMenu === ACCOUNT_MENU) {
      resetActiveMenu()
      return
    }

    setAnchorEl((event.target as HTMLElement).closest('#header_account'))
    onActivateMenu(event, ACCOUNT_MENU)
  }

  const open = useMemo(() => activeMenu === ACCOUNT_MENU, [activeMenu])

  const itemProps: Omit<ListItemProps, 'title' | 'path'> = {
    disableHover: true,
    className: styles.items
  }
  const accountItems = useMemo(() => {
    const res = accountList.slice(0, 4).map((item) => ({
      ...item,
      ...itemProps,
      id: `header_${generateId(item.title)}`,
      onClick: resetActiveMenu
    }))
    if (isLogin) {
      const logoutItem = accountList[4]
      res[3] = {
        ...logoutItem,
        ...itemProps,
        id: `header_${generateId(logoutItem.title)}`,
        onClick: () => {
          resetActiveMenu()
          userSignOut()
          location.href = '/'
        }
      }
    }
    return res
  }, [isLogin])

  return (
    <>
      <ButtonBase
        id="header_account"
        sx={{
          width: '56px',
          height: '56px',
          borderRadius: '32px',
          background: themeMode === 'light' || open ? 'white' : 'transparent',
          p: ' 16px'
        }}
        onClick={handleClick}
      >
        <User24
          replaceColor={[
            ['#26201F', themeMode === 'light' || open ? '#26201F' : 'white']
          ]}
        />
      </ButtonBase>
      <Popper
        open={open}
        anchorEl={anchorEl}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 24]
            }
          }
        ]}
        popperOptions={{ strategy: 'fixed', placement: 'bottom-end' }}
        sx={{ zIndex: 999 }}
      >
        <MenuList
          id="header_orkatwo_menu"
          items={accountItems}
          className={styles.accountMenu}
        />
      </Popper>
    </>
  )
}

export default AccountMenu
