import { Box, Stack } from '@mui/material'
import { useState } from 'react'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { Typography } from '@evocolabs/orkaui'
import ChevronDownSmall24 from '@evocolabs/icons/lib/ChevronDownSmall24'
import styles from './styles.module.scss'
import { ListItem } from './ListItem'
import theme from '@/src/theme'
import { menuList } from './utils'

const AccordionList = ({}) => {
  const [activeMenu, setActiveMenu] = useState('')

  const handleChange = (menu: string) => (_, isExpanded: boolean) => {
    setActiveMenu(isExpanded ? menu : '')
  }

  return (
    <Box className={styles['accordionWrapper']}>
      {menuList.map(({ title, items, extraListNodes }) => {
        const expanded = activeMenu === title
        return (
          <Accordion
            key={title}
            disableGutters
            sx={{ mb: 0 }}
            className={styles.accordion}
            expanded={activeMenu === title}
            onChange={handleChange(title)}
          >
            <AccordionSummary
              expandIcon={<ChevronDownSmall24 />}
              id="header-mobile"
              className={styles.accordionSummary}
            >
              <Typography
                variant="h3"
                color={
                  expanded
                    ? theme.palette.primary[600]
                    : theme.palette.grey[800]
                }
              >
                {title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={styles.accordionDetails}>
              {items.map(({ ...props }) => (
                <ListItem {...props} py={1} px={0} />
              ))}
              <Stack spacing={2}>{extraListNodes}</Stack>
            </AccordionDetails>
          </Accordion>
        )
      })}
    </Box>
  )
}

export default AccordionList
