import React, { Fragment, useMemo } from 'react'
import { Box, ButtonBase, Collapse } from '@mui/material'
import { Typography } from '@evocolabs/orkaui'
import { useRouter } from 'next/router'
import MenuList, { MenuListProps } from './MenuList'
import { generateId } from './utils'
import styles from './styles.module.scss'
import theme from '@/src/theme'
import { useSelector } from 'react-redux'
import { RootState } from '@/src/redux/store'

interface MenuTab {
  title: string
  path: string
}

export type MenuListType = MenuTab & Omit<MenuListProps, 'id'>

export interface MenuBaseProps {
  themeMode: 'light' | 'dark'
  activeMenu: string
  onActivateMenu: (event: React.MouseEvent, title: string) => void
  resetActiveMenu: () => void
}
interface MenuProps extends MenuBaseProps {
  anchorEl: HTMLElement
  menuList: Array<MenuListType>
}

export const Menu = ({ activeMenu, anchorEl, menuList, onActivateMenu, resetActiveMenu, themeMode }: MenuProps) => {
  const promotionStore = useSelector((state: RootState) => state.promotion)
  const promotionBannerClosed = promotionStore?.bannerClosed
  const router = useRouter()

  const collapseSxProps = useMemo(
    () => ({
      zIndex: 999,
      position: 'fixed',
      top: anchorEl
        ? anchorEl.clientHeight + anchorEl.offsetTop + 16 // in case banner exist, cannot simply anchorEl.offsetTop * 2
        : 0,
      left: anchorEl ? anchorEl.offsetLeft : 0
    }),
    [anchorEl, promotionBannerClosed]
  )

  return menuList.map(({ title, items, path, src, extraListNodes }) => (
    <Fragment key={title}>
      <Box padding="14px 16px" className="menu-item">
        <ButtonBase
          id={generateId(title)}
          onClick={() => router.push(path)}
          className={activeMenu === title ? styles.activatedTab : styles.tab}
          onMouseEnter={(e) => {
            onActivateMenu(e, title)
          }}
        >
          <Typography
            variant="Body2"
            className={styles.tab}
            style={{
              color: themeMode === 'light' ? theme.palette.grey[800] : 'white'
            }}
          >
            {title}
          </Typography>
        </ButtonBase>
      </Box>
      <Collapse in={!!activeMenu} sx={collapseSxProps} timeout={200}>
        <MenuList
          id="header_orkatwo_menu"
          items={items}
          onMouseLeave={resetActiveMenu}
          className={styles.menuList}
          src={src}
          extraListNodes={extraListNodes}
          sx={{ display: activeMenu === title ? 'flex' : 'none' }}
        />
      </Collapse>
    </Fragment>
  ))
}

export default Menu
