import { Box, BoxProps, TypographyProps } from '@mui/material'
import Link from 'next/link'
import { Button, Typography } from '@evocolabs/orkaui'
import React from 'react'
import { useDispatch } from 'react-redux'
import theme from '@/src/theme'
import { showLeaveQuestionsModal } from '@/src/components/LeaveQuestions/LeaveQuestionsSlices'

export interface ListItemProps extends BoxProps {
  id?: string
  title: string
  path?: string
  variant?: 'default' | 'link' | 'chat'
  disableHover?: boolean
  typographyProps?: TypographyProps
}

export const ListItem = ({
  id,
  title,
  path,
  variant = 'default',
  disableHover = false,
  typographyProps,
  ...restProps
}: ListItemProps) => {
  const dispatch = useDispatch()
  const itemMapper = {
    default: (
      <Box id={id} px={2} py={1} {...restProps}>
        <Typography
          variant="Body2"
          color={theme.palette.grey[800]}
          sx={{
            cursor: 'pointer',
            '&:hover': disableHover
              ? ''
              : {
                  color: theme.palette.primary[500]
                }
          }}
          {...typographyProps}
        >
          <Link href={path}>{title}</Link>
        </Typography>
      </Box>
    ),
    link: (
      <Box id={id} px={{ xs: 0, dl: 2 }} {...restProps}>
        <Button variant="textprimary" id={id} sx={{ padding: 0 }}>
          <Link href={path}>{title}</Link>
        </Button>
      </Box>
    ),
    chat: (
      <Box id={id} px={{ xs: 0, dl: 2 }} {...restProps}>
        <Button
          variant="textprimary"
          id={id}
          sx={{ padding: 0 }}
          onClick={() => {
            dispatch(showLeaveQuestionsModal())
          }}
        >
          {title}
        </Button>
      </Box>
    )
  }
  return itemMapper[variant]
}

export default ListItem
