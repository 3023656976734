import { Box, BoxProps, Stack } from '@mui/material'
import React, { useRef } from 'react'

import DesktopHeader from './DesktopHeader'
import MobileHeader from './MobileHeader'
import styles from './styles.module.scss'

interface HeaderProps extends Omit<BoxProps, 'className' | 'ref'> {
  isWhiteBg?: boolean
  isFixed?: boolean
  desktopProps?: BoxProps
  mobileProps?: BoxProps
  className?: string
  disableFirstScreenCheck?: boolean
}

const Header = React.forwardRef<HTMLDivElement, HeaderProps>(
  (
    {
      isWhiteBg = false,
      isFixed = false,
      desktopProps,
      mobileProps,
      className,
      children,
      sx,
      disableFirstScreenCheck = false,
      ...restProps
    },
    ref
  ) => {
    const headerRef = useRef<HTMLDivElement>(null)

    // styles
    const headerWrapperClassName = `${
      styles[`headerWrapper${isFixed ? '' : isWhiteBg ? 'WhiteBg' : ''}`]
    } ${className ?? ''}`

    const headerWrapperSx = {
      transition: 'transform 200ms ease-in-out',
      ...sx,
      transform:'unset'
    }

    return (
      <>
        <Box
          className={headerWrapperClassName}
          component="header"
          sx={headerWrapperSx}
          {...restProps}
          ref={ref}
        >
          <Stack ref={headerRef}>
            <DesktopHeader isWhiteBg={isWhiteBg} {...desktopProps} />
            <MobileHeader {...mobileProps} />
          </Stack>
        </Box>
        {children ? <Box className={styles.subheader}>{children}</Box> : null}
      </>
    )
  }
)

export default Header