import joinCDN from './CDN'

export function getImage(
  filename: string,
  device: string,
  folderPath?: string,
  region?: string
): string

export function getImage(
  filename: string,
  device: 'PC' | 'Tablet' | 'Mobile',
  folderPath: string,
  region: string = ''
): string {
  const devicePath = !!device ? `/${device}` : ''
  return joinCDN(`${folderPath}${devicePath}/${filename}`, region)
}
